<template>
  <header class="header">
    <div class="header-inner">
      <!-- Left Location-->
      <div class="header-left hide-md">
        <template v-if="location.data.left">
          <component :is="location.data.left.component" :location="location.data.left" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.left.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-left-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>

      <!-- Center Location-->
      <div class="header-center">
        <div v-if="!location.properties.logo" class="title h1">
          <router-link to="/">{{ location.properties.siteName }}</router-link>
        </div>
        <div v-if="location.properties.logo" class="logo">
          <router-link to="/">
            <img class="img-responsive" :src="location.properties.logo.src" :alt="location.properties.logo.alt" :width="location.properties.logo.width" :height="location.properties.logo.height" />
          </router-link>
        </div>
        <template v-if="location.data.center">
          <component :is="location.data.center.component" :location="location.data.center" v-on="$listeners"></component>

          <template v-for="childBlock in location.data.center.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'header-center-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </div>

      <!-- Right Location-->
      <div class="header-right">
        <template v-if="location.data.appBox">
          <ul class="app-box">
            <component :is="location.data.appBox.component" :location="location.data.appBox" v-on="$listeners"></component>

            <template v-for="childBlock in location.data.appBox.blocks">
              <component :is="childBlock.component" :block="childBlock" :key="'header-right-' + childBlock.id" v-on="$listeners"></component>
            </template>
          </ul>
        </template>
      </div>
    </div>

    <!-- Bottom Location -->
    <div class="header-bottom">
      <template v-if="location.data.bottom">
        <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

        <template v-for="childBlock in location.data.bottom.blocks">
          <component :is="childBlock.component" :block="childBlock" :key="'header-center-' + childBlock.id" v-on="$listeners"></component>
        </template>
      </template>
    </div>
  </header>
</template>

<style>
.header {
  position: relative;
  z-index: 2;
}
.header .header-inner {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 25px 0;
  border-bottom: 1px solid #f6f6f6;
}
.header .mobile-search {
  padding: 10px 20px;
  border-bottom: 1px solid #f6f6f6;
}
.header .title {
  text-align: center;
  font-size: 4vw;
}
.header-left,
.header-right {
  display: flex;
  align-items: center;
}
.header .logo {
  display: flex;
  justify-content: center;
}

.header .social-box,
.header .app-box {
  list-style-type: none;
  margin: 0;
  padding: 0;
  --gap: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  /* margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap)); */
  width: calc(100% + var(--gap));
}
.header .social-icon {
  margin: var(--gap) 0 0 var(--gap);
}
.header .app-box {
  justify-content: flex-end;
}
.header .app-icon {
  margin: var(--gap) var(--gap) 0 0;
}
.header .social-icon span[class^='svg-'],
.header .app-icon span[class^='svg-'] {
  background-color: lightgray;
}
@media (max-width: 840px) {
  .header .header-inner {
    grid-template-columns: 1fr auto;
  }
  .header .title {
    margin-left: 10px;
    text-align: left;
    font-size: 7vw;
  }
}
</style>

<script>
export default {
  name: 'defaultHeader',
  props: {
    location: Object,
  },
  head: {
    title: function() {
      return {
        inner: this.location.properties.pageTitle,
        separator: '|',
        complement: this.location.properties.siteName,
      };
    },
    meta: function() {
      return [
        // Basic
        { rel: 'canonical', href: this.location.properties.path, id: 'canonical' },
        { name: 'description', content: this.location.properties.synopsis, id: 'synopsis' },
        // Twitter Share
        { name: 'twitter:title', content: this.location.properties.pageTitle, id: 'twitterTitle' },
        { name: 'twitter:url', content: this.location.properties.path, id: 'twitterUrl' },
        { name: 'twitter:description', content: this.location.properties.synopsis, id: 'twitterDesc' },
        // { name: 'twitter:image', content: 'TODO - Logo Path 4 Twitter', id: 'twitterImage'},
        // { name: 'twitter:site', content: 'TODO - @A Twitter URL', id: 'twitterPage'},
        // { name: 'twitter:creator', content: 'TODO - @A Twitter UserName', id: 'twittername'},
        // Open Graph
        { name: 'og:site_name', content: this.location.properties.siteName, id: 'ogSite' },
        { name: 'og:type', content: 'website', id: 'ogType' },
        { name: 'og:title', content: this.location.properties.pageTitle, id: 'ogTitle' },
        { name: 'og:description', content: this.location.properties.synopsis, id: 'ogDesc' },
        // { name: 'og:image', content: 'TODO - Logo Path 4 Facebook', id: 'ogImage'},
        { name: 'og:url', content: this.location.properties.path, id: 'ogUrl' },
      ];
    },
  },
  data() {
    return {};
  },
};
</script>
